import React, { useState, useContext, useEffect  } from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from "../pages/auth/login";
import UpdateProfile from '../pages/auth/profile';
import InternDashboard from '../pages/dashboards/internDashboard';
import Leads from '../pages/leads/leads';
import Payments from '../pages/payments/payments';
import SideNavbar from '../components/navbar/navbar';
import Training from '../pages/tutorial/tutorials';
import { AuthContext } from '../context/authContext';
import UserPayments from '../pages/users/payment';
import Checkout from '../pages/users/checkout';
import PaymentRedirect from '../pages/users/redirect';
// import AdminRoutes from './adminRoute';

import AddUsers from '../pages/admin/users/addUsers';
import UsersList from '../pages/admin/users/usersList';
import CourseList from '../pages/admin/courses/courseLIst';
import SelectCourse from '../pages/users/course-selection';
import CouponList from '../pages/admin/coupon/couponList';


const MainRoutes = () => {
    const [path, setPath]= useState("");
    const [isAdmin, setIsAdmin] =  useState(false);

    const auth = useContext(AuthContext)
    console.log("auth contextfrom auth", auth)

    if(path!==window.location.pathname){
        const loc = window.location.pathname;
        setPath(loc);
    }
    
    useEffect(()=>{
        const loc = window.location.pathname;
        setPath(loc);
        if(auth && auth.authUser)setIsAdmin(auth.authUser.hasuraUserData.is_admin);
        else setIsAdmin(false)
    },[auth.authUser])

    return (<div className='bg-slate-50' style={{ "minHeight": "100vh" }}>
        
        <div className="flex">
       {auth.authUser && path!=='/login'&&path!=="payment"&&path!=="checkout" &&
       (!path.includes("payment-redirect"))&&
        <SideNavbar className="w-2/12"/>}
        <div className="w-10/12 grow ml-5">
        <Routes className="" style={{}}>
            <Route path="login" element={ <Login />}
            />
            <Route path="leads" element={<Leads />} />
            <Route path="dashboard" element={<InternDashboard />} />
            <Route path="profile" element={<UpdateProfile />} />
            <Route path="payments" element={<Payments />} />
            <Route path="training" element={<Training />} />
            {isAdmin && <Route path="admin/addUsers" element={<AddUsers />} />}
            {isAdmin && <Route path="admin/users" element={<UsersList />} />}
            {isAdmin && <Route path="admin/courses" element={<CourseList />} /> }
            {isAdmin && <Route path="admin/coupon" element={<CouponList />} /> }
            <Route path="payment-redirect/:id" element={<PaymentRedirect />} />
            <Route path="payment" element={<UserPayments />} />
            <Route path="select-course" element={<SelectCourse />} />
            <Route path="checkout" element={<Checkout />} />
        </Routes>
        </div>
        </div>
    </div>);
}

export default MainRoutes;