import React, {useState} from 'react';
import axios from 'axios';
import env from "../../../utils/config";


const AddCourses = ({refreshList}) => {

    const [formData, setFormData] = useState({
      "course_type":"Internship",
      "course_name":"",
      "course_fee":0,
      "month":"March",
      "course_plan":"Self Placed",
      "info": ""
    });

    const [loading, setLoading] =useState(false);
    const [toast, setToast] = useState({data:"", color:"sky"});

    const formDataChange = (name, value) =>{

      console.log(formData)
      console.log(name, value)
      setFormData(prev=>({
        ...prev,
        [name]:value
      }))
    }

    const showTost = (data, color)=>{
      setToast({data, color})
      setTimeout(()=> setToast({data:"", color:"violet"}), 3000)
  }


    const submitHandler = async()=>{
      setLoading(true)
      try{
        const url = `${env.BACKEND_URL}/course`
        const data = {batchMonth: formData.month,courseFee: formData.course_fee ,courseInfo: formData.info,courseName: formData.course_name,courseType: formData.course_type, coursePlan: formData.course_plan}
        const response = await axios.post(url, data);

        // setTableRows(response.data.users)
        console.log(response.data)
        setLoading(false)
        showTost("Course added", "violet")
        refreshList()

    }catch(e){
          setLoading(false)
      if(e.isAxiosError)
      {console.log(e.response.data.error)
        showTost(e.response.data.error, "red")
      }
  else{
      console.log(e)
      showTost("Unkown error", "red");
  }}

    }

    return (<>

<div>
{toast.data!=='' && <div id="toast-top-right" className={`absolute flex items-center w-full max-w-xs p-4 mb-2 space-x-4 font-xl font-semibold bg-${toast.color}-500 text-white divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 delay-200 ease-in-out `} role="alert">
    <div className="text-md font-semibold">{toast.data}</div>
</div>}

    <div className="bg-white shadow-md mb-3 rounded-md md:p-10 ">
    <h2 className="font-bold text-2xl mx-4 mb-5 tracking-widest">Add Course </h2>
      <div className="flex">
      {/* <div className="w-full  px-3 mb-6 md:mb-2">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
        Course Type
      </label>
      <div className="relative">
        <select name="course_type" onChange={(input)=>formDataChange(input.target.name, input.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
          <option>Internship</option>
          <option>Job Guarantee</option>
          <option>Workshop</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div> */}

    <div className="w-full px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
        Course Name
      </label>
      <input name="course_name" onChange={(input)=>formDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Artificial Intelligence" />
      {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
    </div>

      </div> 

      <div className="flex">



    <div className="w-full px-3 mb-6 md:mb-0 ">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
        Course Fees
      </label>
      <input name="course_fee" onChange={(input)=>formDataChange(input.target.name, input.target.value)}className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="number" placeholder="3000" />
      {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
    </div>

    {/* <div className="w-full  px-3 mb-6 md:mb-2">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
        Batch Month
      </label>
      <div className="relative"  >
        <select name="month" onChange={(input)=>formDataChange(input.target.name, input.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div> */}
    </div>
    
    <div className="">

<div className='flex'>

{/* <div className="w-full  px-3 mb-6 md:mb-2">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
        Course Plan
      </label>
      <div className="relative">
        <select name="course_plan" onChange={(input)=>formDataChange(input.target.name, input.target.value)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
          <option>Self Placed</option>
          <option>Mentor Led</option>
          <option>Advanced</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div> */}

    <div className="w-full px-3 mb-6 md:mb-0">
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
        Course Info
      </label>
      <input name="info" onChange={(input)=>formDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Paste the URL here" />
      {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
    </div>

</div>

     <button type='submit' className='mx-3 px-4 py-2 bg-violet-400 text-white rounded-md shadow-md my-5 text-right block' onClick={submitHandler}>
       {loading && <span>LOadding...</span>}
       Add Course
       </button>
    </div>

    </div>
</div>

    </>);
}

export default AddCourses;