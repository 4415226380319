import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/authContext';
import env from "../../utils/config"

const Leads = (props) => {

    const [tableHeaders, setTableHeaders] = useState();
    const [tableBody, setTableBody] = useState();
    const [loadingTable, isLoadingTable] = useState(false);
    const [toast, setToast] = useState("invisible")

    const auth = useContext(AuthContext);

    console.log(props)

    useEffect(async () => {
        // axios.post("dshfdsfdkf", {}, {})
        setTableHeaders([{ "name": "Customer", "id": 0 }, { "name": "Contact", "id": 2 }, { "name": "AOI", "id": 4 }, { "name": "Submitted", "id": 5 }, { "name": 'Role', "id": 6 }])
    }, [])


    const hideToast = ()=>{
        setToast('visible')
        setTimeout(()=> setToast("invisible"), 3000)
    }

    useEffect(async () => {
        // setToast("visible")
        isLoadingTable(true)
        const url = `${env.BACKEND_URL}/typeform/${auth.authUser.hasuraUserData.email}`
        console.log(url)
        const response = await axios.get(url);
        console.log(response.data)

        setTableBody(response.data.typeform);
        isLoadingTable(false)
    }, [auth])

    let promotionLink = ""

    if(auth && auth.authUser) promotionLink = auth.authUser.hasuraUserData.short_form_url;

    return (
        <div className='mx-5'>


            <div className="bg-white rounded-lg my-5 pb-5 mt-10 mb-5 shadow-md">

            <div id="toast-top-right" className={`absolute flex items-center w-full max-w-xs p-4 space-x-4 font-xl font-semibold bg-sky-400 text-white divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 delay-200 ease-in-out ${toast}`} role="alert">
    <div className="text-md font-semibold">Link copied</div>
</div>

                <div className="flex">
                    <div className="w-6 h-12 bg-sky-300 my-1 mx-4 rounded-lg">{' '}</div>
                    <h2 className="font-semibold text-xl tracking-wide mt-2 pt-3 px-2 pb-3">Promotion Link</h2>
                </div>
                <span className='font-semibold text-slate-300 py-5 ml-4'>Use this link to promote</span>

                <div className='border-2 ml-4 px-5 py-3 mb-8 w-1/2 mt-4 border-sky-300 shadow-md flex rounded-md hover:cursor-pointer'  onKeyDown={()=>console.log("hi")} tabIndex={0} role="button" onClick={()=>{navigator.clipboard.writeText(promotionLink); hideToast()}}><span className='basis-5/6'>Referal Link - {promotionLink} </span> <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 basis-1/6 text-right ml-auto mr-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg> </div>

            </div>
            <h2 className="text-3xl tracking-wide mt-2 pt-3 px-2 pb-3">Leads</h2>
            <div className="bg-white shadow-md scroll-smooth overflow-auto max-width-full px-5">
                
                <div className="flex">
                    <div className="w-6 h-20 bg-violet-300 my-3 mx-2 rounded-lg">{' '}</div>
                    <div className="flex flex-col">
                    <h2 className="text-2xl tracking-wide mt-2 pt-3 px-2 pb-2">Customer</h2>
                    <span className='font-semibold text-slate-300 py-1 ml-2'>LIst of customers intrested on your link</span>
                    </div>
                </div>

                <table className="table-auto w-full">
                    <thead>
                        <tr className='rounded-t-3xl border-b-2 border-slate-100 text-slate-500 text-left'>
                            {tableHeaders && tableHeaders.map(data => <th key={data.id} className=" px-6 py-4">{data.name}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {loadingTable && (<svg role="status" className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 text-center my-5" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>)}
                        {!loadingTable && tableBody && tableBody.map(data => (
                            <tr key={data.id}>
                                <td className='px-6 py-4 '>
                                    
                                    <div className="flex flex-col">
                                        <div >{`${data.first_name} ${data.laste_name}`}</div>
                                        <div className='text-slate-500'>{data.college}</div>
                                    </div>
                                    </td>
                                <td className='px-6 py-4 '>
                                    <div className="flex flex-col">
                                        <div>{data.email}</div>
                                        <div className='text-slate-500'>{data.phone_no}</div>
                                    </div>
                                    
                                    
                                    </td>
                                <td className='px-6 py-4 '>{data.area_of_intrest}</td>
                                <td className='px-6 py-4 '>{new Date(data.submitted_at).toLocaleString()}</td>
                                <td className='px-6 py-4 '>{data.role}</td>
                            </tr>

                        ))}
                    </tbody>
                </table>

                {!loadingTable && tableBody && tableBody.length===0 && <p className='text-center text-gray-300 py-5 font-bold text-xl '>No Data</p> }

            </div>
        </div>
    );
}

export default Leads;