import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import env from '../../utils/config';

const SelectCourse = () => {

    const [isCouponVisible, setCouponVisible] = useState(false);
    const [plan, setPlan]  = useState("pro");
    const [type, setType] = useState("single-course");

    const [coupon, setCoupon]  = useState("");
    const [userCourseList, setUserCourseList] = useState([{}])
    const [couponLoading, setCouponLoading] =  useState(false);
    const [couponResult, setCouponResult] =  useState({discount:0});
    const [courseList, setCourseList] =  useState([]);

    const navigate = useNavigate();

    const onCouponChange =  ({target})=>{
        setCoupon(target.value);
    }

    useEffect(async()=>{
        try {
            const url = `${env.BACKEND_URL}/course/list`;
            const response = await axios.get(url);
            console.log(url);
            
            console.log(response.data)
            setCourseList(response.data.course)
          } catch (e) {
      
            if (e.isAxiosError) {
              console.log(e.response.data.error)
            }
            else {
              console.log(e)
            }
          }
    },[])

    const setPlanWrapp =(event)=>{
        
        const localPlan = event.target.value;
        console.log(localPlan)
        setPlan(localPlan);
        if(localPlan==="pro") setUserCourseList([{}, {}, {}])
        if(localPlan==="vip") setUserCourseList([{}, {}, {}, {}, {}])

        console.log(userCourseList);
    }

    const setTypeWrapp =(event)=>{
        
        const localType= event.target.value;
        console.log(localType, localType==="pro", "data")
        setType(localType);
        if(localType==='single-course') setUserCourseList([{}]);
        if(localType==='multiple-course') setPlanWrapp({target:{value:"pro"}})
    }

    const applyCoupon = async()=>{

        const reducedList = userCourseList.filter(data=> !data.id);
        if(reducedList.length)
        {
             setCouponResult({valid:false,discount:0,msg:"Please select all the course"}); setCouponLoading(false); return;
        }
        
        try {
            setCouponLoading(true);

            const url = `${env.BACKEND_URL}/coupon/${coupon}`;
            const response = await axios.get(url);
            console.log(url);
            
            console.log(response.data)
            if(response.data.coupon.length) {setCouponResult({valid:false,discount:0,msg:"Invalid coupon"}); setCouponLoading(false);}
            const couponData  = response.data.coupon;
            

            const amount = userCourseList.map(data=>data.fee).reduce((prev, curr)=>prev+curr);
        
            const {coupon_info: couponInfo, expires_at : expiresAt, amount_percent_value: amountPercentValue, condition_key: conditionKey, condition_value : conditionValue, is_amount_percent: isAmountPercent,  max_no_of_coupon: maxNoOfCoupon, id} = couponData[0];

            if(expiresAt!==null && expiresAt!=='')
            if(new Date(expiresAt)> new Date())  {setCouponResult({valid:false,discount:0,msg:"Coupon Expired"}); setCouponLoading(false);}

            if(maxNoOfCoupon < 0 && maxNoOfCoupon !== -1 ) {setCouponResult({valid:false,discount:0,msg:"All Coupons consumed"}); setCouponLoading(false);}
        
            const discountPrice = ()=>{
                let discount;
                console.log("toatal", amount*amountPercentValue/100); console.log(amount, amountPercentValue, isAmountPercent)
                if(isAmountPercent==="amount") discount= amountPercentValue;
                else {discount = amount*amountPercentValue/100; console.log("toatal", amount*amountPercentValue/100); console.log(amount, amountPercentValue)}
                if(discount<0) discount=  0

                setCouponResult({valid:true,msg:couponInfo, id, discount });
        
            }
            switch(conditionKey){
        
                case "course-count": {
                    if(conditionValue !== courseList.length) {setCouponResult({valid:false,discount:0,msg:"Invalid coupon"}); setCouponLoading(false);}
                    discountPrice();
                    break;
                }
                case "course": {
                    if(conditionValue !== courseList[0].id) {setCouponResult({valid:false,discount:0,msg:"Invalid coupon"}); setCouponLoading(false);}
                    discountPrice();
                    break;
                }
                default: discountPrice()
            }

            setCouponLoading(false);
          } catch (e) {
      
            if (e.isAxiosError) {
              console.log(e.response.data.error)
            }
            else {
              console.log(e)
            }

            setCouponResult({valid:false,discount:0,msg:"Error Occured"}); setCouponLoading(false);
          }
        
    }

    const courseSelection = (rawValue, index)=>{
       const value = JSON.parse(rawValue);

        setUserCourseList(prev=>{
            const localArray = [...prev];
            localArray[index] = {id: value.id, name: value.name, fee: value.fee, index:value.index}
            return localArray;
        })

        setCouponResult(null)
    }

    const currentLength = ()=>{
        if(type==='single-course') return 1;
        if(plan==="pro") return 3;
        if(plan==='vip') return 5;

        return null;
    }

    const submitHandler = ()=>{
        const requiredLength = currentLength();
        const filteredCourse =  userCourseList.filter(data=>data.id)
        if(filteredCourse.length !== requiredLength ){alert("Please select all the course"); return;}
        let selectedCourses = {}
        userCourseList.map(data=>{selectedCourses[data.id]={}; return 'data'});

        selectedCourses = Object.keys(selectedCourses);
        if(selectedCourses.length !== requiredLength) {alert("All selected course must be unique"); return;}
        console.log(selectedCourses);

        const courseInfo = JSON.parse(localStorage.getItem("courseInfo"));

        courseInfo.courseList =userCourseList;
        courseInfo.courseIdList =selectedCourses;
        courseInfo.coupon =  couponResult ? {id: couponResult.id, discount: couponResult.discount}: {id: null, discount: 0};

        localStorage.setItem("courseInfo", JSON.stringify(courseInfo))

        navigate("/checkout")
    }

    const totalAmount = userCourseList.map(data=>data.fee).reduce((value1, value2)=>value1+value2);
    console.log(userCourseList);
    console.log( userCourseList.map(data=>data.course_fee));
    console.log( userCourseList.map(data=>data.course_fee).reduce((value1, value2)=>value1+value2))
    return (
        <>
            <div>
                <div className="bg-white shadow shadow-xl px-4 py-2 rounded-lg md:w-6/12">

                <div className="flex flex-cols">
        <div className="w-3 h-16 bg-indigo-400 my-1 mr-4 rounded-lg">{' '}</div>
        <div className=''>
        <h2 className=" text-3xl tracking-wide  pt-2 pb-1">Mentorboxx courses </h2>
        <div className='text-slate-300 pb-3 '>Course Info </div>
        </div>
        
      </div>
      

                    <div className="course-options mb-4">
                    <h3 className="text-md font-bold">Select no of course</h3>
                        <div className="flex my-2" value={type} onChange={setTypeWrapp}>
                            <div className={`form-check px-3 py-2 rounded-lg outline-2 outline checked:outline-violet-400 mr-2 ${type==="single-course"? "outline-violet-400 bg-violet-100": " outline-slate-200 "}`}>
                                <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-violet-400 checked:border-violet-400 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="course-type" id="single-course"  value="single-course" defaultChecked
                              
                                />
                                <label className="form-check-label inline-block " htmlFor="single-course">
                                    Single Course
                                </label>
                            </div>

                            <div className={`form-check px-3 py-2 rounded-lg outline-2 outline checked:outline-violet-400 mr-2 ${type==="multiple-course"? "outline-violet-400 bg-violet-100": " outline-slate-200 "}`}>
                                <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-violet-400 checked:border-violet-400 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="course-type" id="multiple-course" value="multiple-course"
                                
                                />
                                <label className="form-check-label inline-block " htmlFor="multiple-course">
                                    Multiple Course
                                </label>
                            </div>

                        </div>
                    </div>

                    {type==="multiple-course" && 
                    <div className="plan-options">
                        <h3 className="text-md font-bold my-2">Select plan</h3>
                        <div className="flex" value={plan} onChange={setPlanWrapp}>

                            <div className={`form-check px-3 py-2 rounded-lg outline-2 outline checked:outline-violet-400 mr-2 ${plan==="pro"? "outline-violet-400 bg-violet-100": " outline-slate-200 "}`}>
                                <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-violet-400 checked:border-violet-400 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="course-plan" id="pro"  value="pro" defaultChecked
                                
                                />
                                <label className="form-check-label inline-block " htmlFor="pro">
                                    Pro
                                </label>
                            </div>

                            <div className={`form-check px-3 py-2 rounded-lg outline-2 outline checked:outline-violet-400 mr-2  ${plan==="vip"? "outline-violet-400 bg-violet-100": " outline-slate-200 "}`} >
                                <input className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-violet-400 checked:border-violet-400 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="course-plan" id="vip" value="vip"
                                
                                />
                                <label className="form-check-label inline-block " htmlFor="vip">
                                    VIP
                                </label>
                            </div>
                        </div>
                        
                    </div>}

                    
                    <div className="course-list">
                    <h3 className="text-md font-bold my-2">Select course</h3>
                        {userCourseList.map((selectedCourse, courseSelectIndex)=>
                            <>
                                 <div className="relative mb-2 ">
              <select name="course_plan" 
              value={selectedCourse.id?JSON.stringify(selectedCourse): "default"}   
              isSear
              onChange={(event)=>courseSelection(event.target.value, courseSelectIndex)} 
              className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed" id="grid-state">
              <option value="default" selected disabled>Select course</option>
                            
                 {courseList.map((data, index)=>(<option key={data.id} 
                 value={(()=>{ console.log(JSON.stringify(selectedCourse), JSON.stringify({ id:data.id, name:data.course_name,fee:data.course_fee,index}));
                  
                 return JSON.stringify({ id:data.id, name:data.course_name,fee:data.course_fee,index})
             })()}
                 >{data.course_name}</option>))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
                            </>
                        )}
                    </div>

                    <div className="coupon my-5">
                            {!isCouponVisible &&
                                <button type="button" className='w-full px-4 outline-none text-violet-400  text-center block' onClick={() => setCouponVisible(true)}>
                                    Apply Coupon
                                </button>}

                            {isCouponVisible && <div className="coupon-field transition ease-in-out delay-200 ">
                                <div className='flex'>
                                    <div>
                                        <input type="text" placeholder='Coupon Code' onChange={onCouponChange} value={coupon} className='form-input focus:outline-none border-b-2 px-2 py-2 mt-2 border-solid border-sky' />
                                    </div>
                                    <div>
                                        <button type="button" className='mx-3 px-4 py-2 bg-violet-400 text-white rounded-md shadow-md my-5 text-right block' onClick={applyCoupon} disabled={couponLoading}>
                                            {couponLoading?"Load.." :"Apply"}
                                        </button>
                                    </div>

                                </div>
                                {couponResult && <p className={`font-bold text-center ${couponResult.valid?"text-emerald-600":"text-rose-600"}`}>{couponResult.msg}</p>}
                            </div>}
                        </div>

                     <div className='buy-button'>
                            <button type="button" className='w-full px-4 py-2 mr-4 my-5 bg-violet-400 text-white rounded-md shadow-md text-center block' onClick={submitHandler}>

                                Make Payment
                            </button>
                        </div>
                        <div className="amount">
                            
                                {userCourseList.map(data=> (data.id?(<>
                                    <div className="flex px-2 py-1" key={data.id}>
                                <div className='text-left w-1/2'>{data.name}</div>
                                <div className='text-right w-1/2'>Rs. {data.fee}</div>
                            </div> </>):<></>)
                                )}
                                
                                { isCouponVisible && couponResult && couponResult.valid &&
                                    <div className="flex px-2 py-2">
                                <div className='text-left w-1/2 font-semibold'>Coupon</div>
                                <div className='text-right w-1/2'>-{couponResult.discount}</div>
                                </div>   
                                }
                                {!Number.isNaN(totalAmount) && totalAmount && totalAmount!==undefined && 
                                <div className="flex px-2 py-2">
                                <div className='text-left w-1/2 font-semibold'>Total</div>
                                <div className='text-right w-1/2'>Rs. {couponResult?totalAmount-couponResult.discount:totalAmount}</div>
                                </div>   
                                }
                            
                        </div>
                </div>
            </div>
        </>
    );
}

export default SelectCourse;