import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import env from '../../utils/config';



const Checkout = () => {

    const [baseInfo, setBaseInfo] = useState(null);
    const navigate = useNavigate();
    // const [course, setCourse] = useState(null);
    const[payment, setPayment] =  useState(null);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [toast, setToast] = useState({data:"", color:"sky"});

    const totalAmount = baseInfo ?baseInfo.courseList.map(data=>data.fee).reduce((value1, value2)=>value1+value2):0;

    const loadScript=(src) =>
        ( new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        }));


    useEffect(async () => {
        const localData = localStorage.getItem("courseInfo");
        if (!localData) navigate("/payment");
        const infos = JSON.parse(localData);

        setBaseInfo(infos);
    }, [])

    const showTost = (data, color)=>{
        setToast({data, color})
        setTimeout(()=> setToast({data:"", color:"violet"}), 3000)
    }


    const displayRazorpay = async ()=> {

        setPaymentLoading(true)
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        
        try{

        
        // creating a new order
         const url = `${env.BACKEND_URL}/payment/cart`;
         const orderData = {courseList: baseInfo.courseIdList,couponId: baseInfo.coupon.id}
         const result = await axios.post(url, orderData);

        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        console.log("result",result);
        const { amount, id: orderId, currency } = result.data.cart;

        const options = {
            key: env.RAZORPAY_ID, // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency,
            name: "Mentorboxx",
            description: "New Standard of learning practically",
            image:  "https://static.wixstatic.com/media/4eceb7_ccf5a45342124a28be9b6654dc9aad91~mv2.png/v1/fill/w_57,h_40,al_c,usm_0.66_1.00_0.01,enc_auto/1123Asset%202%404x.png" ,
            order_id: orderId,
            handler: async (response)=> {

                const refId = localStorage.getItem('refId');

                const data = {
                    orderCreationId: orderId,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    amount: baseInfo.coupon.discount?(totalAmount + ((totalAmount - baseInfo.coupon.discount)* 2 / 100) - baseInfo.coupon.discount ):(totalAmount + ((totalAmount)* 2 / 100) ),
                    refUid: refId,
                    coupon_id: baseInfo.coupon.id, 
                    courseList: baseInfo.courseIdList, 
                    email: baseInfo.userEmail, 
                    phone: baseInfo.userPhone, 
                    name: baseInfo.userName,

                };
                
                const sucUrl = `${env.BACKEND_URL}/payment/success`;
                const sucResult = await axios.post(sucUrl, data);

                setPayment(sucResult.data);
                let trnsDatas = localStorage.getItem("transaction");
                if(trnsDatas){
                    trnsDatas= JSON.parse(trnsDatas)
                    trnsDatas.transactionList.push(sucResult.data)
                }
                else{
                    trnsDatas.transactionList= [sucResult.data]
                }
                setPaymentLoading(false);
            },
            prefill: {
                name: baseInfo.userName,
                email: baseInfo.userEmail,
                contact: baseInfo.userPhone,
            },
            notes: {
                // courseId: course.id,
                // courseAmount: course.course_fee,
            },
            theme: {
                color: "#A78BFA",
            },

            
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();

        setPaymentLoading(false);
    }
    catch(e){
        
        if (e.isAxiosError) {
            console.log(e.message, "Error")
            showTost({data:e.message.toString(),color: "red"})
                
            
        }
        else {
            showTost({data:e.message.toString(), color:"red"})
        }
        setPaymentLoading(false);
    }
}

    return (
        <div>

{toast.data!=='' && <div id="toast-top-right" className={`absolute flex items-center w-full max-w-xs p-4 mb-2 space-x-4 font-xl font-semibold bg-${toast.color}-500 text-white divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 delay-200 ease-in-out `} role="alert">
      <div className="text-md font-semibold">{toast.data.data}</div>
  </div>}

            <div className="bg-white rounded-lg my-5 px-5 pb-5 mt-10 mb-5 shadow-md md:w-4/6">

            <div className="flex flex-cols px-5 md:px-8">
                <div className="w-3 h-16 bg-violet-400 my-1 mr-4 rounded-lg">{' '}</div>
                <div className=''>
                <h2 className=" text-xl md:text-3xl tracking-wide  pt-2 pb-1">Mentorboxx courses </h2>
                <div className='text-slate-300 pb-3 '>{payment?"Transaction Details":"Checkout courses"} </div>
                </div>
            </div>

                {baseInfo && <div>
                    <div className="flex flex-col lg:flex-row">
                        <div className='flex flex-col px-5 md:px-8  basis-1/2'>
                            <h2 className='text-lg pb-2 md:pb-0 md:text-2xl mb-3 border-b-2 text-slate-400 md:text-black-800'>User Info</h2>
                            <div className="flex flex-row">
                                <div className=' basis-1/2'>
                                    <p className='py-2'>Name</p>
                                    <p className='py-2'>College</p>
                                    <p className='py-2'>Email</p>
                                    <p className='py-2'>Phone</p>
                                    {/* <p className='py-2 text-slate-400'>Cours Info</p> */}
                                </div>
                                <div className=' basis-1/2 text-right md:text-left'>
                                    <p className='py-2'>{baseInfo.userName}</p>
                                    <p className='py-2'> {baseInfo.userCollege}</p>
                                    <p className='py-2'>{baseInfo.userEmail}</p>
                                    <p className='py-2'>{baseInfo.userPhone}</p>
                                    {/* <p>{course.course_name}-{course.batch_month}-{course.course_plan}</p> */}
                                </div>

                            </div>
                        </div>
                        <div className='basis-1/2'>
                           {!payment && <div className='mx-3 md:mx-5 border-t-2 md:border-none my-2 py-2 md:my-0 md'>
                            <h2 className='text-2xl mb-3 border-b-2 hidden md:block'>Course Info</h2>

                            <div className="flex px-2 py-2 md:py-1 font-semibold text-slate-400" >
                                <div className='text-left w-3/4 md:w-1/2'>Course Name</div>
                                <div className='text-right w-1/2'>Fee</div>
                            </div> 

                            {baseInfo.courseList.map(data=>
                                <div className="flex px-2 py-2 md:py-1" key={data.id}>
                                <div className='text-left w-3/4 md:w-1/2'>{data.name}</div>
                                <div className='text-right w-1/2'>Rs. {data.fee}</div>
                            </div> 
                            )}

                           {baseInfo.coupon.id && <div className="flex px-2 py-2 md:py-1" >
                                <div className='text-left w-3/4 md:w-1/2'>Coupon</div>
                                <div className='text-right w-1/2'>Rs. -{baseInfo.coupon.discount}</div>
                            </div> }
                            <div className="flex px-2 py-2 md:py-1" >
                                <div className='text-left w-3/4 md:w-1/2'>Payment gateway </div>
                                <div className='text-right w-1/2'>Rs. {baseInfo.coupon.discount?((totalAmount - baseInfo.coupon.discount)* 2 / 100):((totalAmount)* 2 / 100)}</div>
                            </div> 
                            
                            <div className="flex px-2 py-3 border-t-2 " >
                                <div className='text-left w-3/4 md:w-1/2 font-bold'>Total</div>
                                <div className='text-right w-1/2'>Rs. {baseInfo.coupon.discount?(totalAmount + ((totalAmount - baseInfo.coupon.discount)* 2 / 100) - baseInfo.coupon.discount ):(totalAmount + ((totalAmount)* 2 / 100) )}</div>
                            </div> 
                            
                            <div>
                            <button className="w-full px-2 py-2 bg-violet-400 text-white rounded-md shadow-md my-1 block disabled:cursor-not-allowed" disabled={paymentLoading} onClick={displayRazorpay}type='submit'>
                                        {paymentLoading?(<div className=' w-full flex text-center'><svg role="status" className="text-center w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-slate-50 my-1" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg> <span className='mx-5 inline-block text-center w-full'>Processing..</span></div>):<div className='px-1 py-1'> Pay Now</div> } 
                                    </button>
                            </div>
                                

                            </div>}
                            {payment && <>
                                <div className='px-3 md:px-0'>
                                <h2 className='text-lg pb-2 md:pb-0 md:text-2xl mb-3 border-b-2 text-slate-400 md:text-black-800'>Transaction Info</h2>
                                <div className="flex flex-col">
                                <div className="flex px-2 py-3 " >
                                    <div className='text-left w-1/4 md:w-1/2 '>Transaction ID</div>
                                    <div className='text-right w-3/4 md:w-1/2 text-sm'>{payment.id}</div>
                                </div>

                                <div className="flex px-2 py-3 " >
                                    <div className='text-left w-1/4 md:w-1/2 '>Payment ID</div>
                                    <div className='text-right w-3/4 md:w-1/2'>{payment.paymentId}</div>
                                </div>

                                <div className="flex px-2 py-3 " >
                                    <div className='text-left w-1/4 md:w-1/2 '>Total Fee</div>
                                    <div className='text-right w-3/4 md:w-1/2'>Rs. {payment.amount}</div>
                                </div> 
                                </div>
                                
                                
                                <button className="w-full px-4 py-2 bg-violet-400 text-white rounded-md shadow-md my-5 text-center block" onClick={window.print}type='submit'>
                                       Print
                                    </button>
                            </div>
                            </>}
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default Checkout;