import React from 'react';
import './App.css';
import MainROutes from './routes/mainRoutes';
import AuthContextComponent from './context/authContext';

const App = () => {

  console.log("Hi")
  return (
    <AuthContextComponent>
      <MainROutes />
  </AuthContextComponent> 
  );
}

export default App;
