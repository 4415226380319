import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import env from '../../utils/config';

const UserPayments = () => {

  const [formData, setFormData] = useState({
    "user_name": "",
    "user_email": "",
    "user_phone": "",
  });

  // const [course, setCourse] = useState([]);
  // const [batch, setBatch] = useState([]);
  // const [plan, setPlan] = useState([]);
  const [toast, setToast] = useState(null);

  // const [selectedCourse, setSelectedCourse] = useState({});
  const navigate = useNavigate();

  // const clearSelection = (value) => {

  //   switch (value) {
  //     case "course_type":
  //       {
  //         setCourse([]); setBatch([]); setPlan([]); setSelectedCourse({});
  //         setFormData((prev) => ({
  //           ...prev,
  //           "course_name": "",
  //           "course_batch": "",
  //           "course_plan": "",
  //         }));
  //         break;
  //       }
  //     case "course_name":
  //       {
  //         setBatch([]); setPlan([]); setSelectedCourse({});
  //         setFormData(prev => ({
  //           ...prev,
  //           "course_batch": "",
  //           "course_plan": "",
  //         }));
  //         break;
  //       }
  //     case "course_batch":
  //       {
  //         setPlan([]); setSelectedCourse({});

  //         setFormData(prev => ({
  //           ...prev,
  //           "course_plan": "",
  //         }));
  //         break;
  //       }

  //     default:
  //       setSelectedCourse({})
  //   }

  // }


  // const getPlan = async (courseBatch) => {
  //   clearSelection("course_batch")
  //   try {
  //     const url = `${env.BACKEND_URL}/course/plan/${formData.course_type}/${formData.course_name}/${courseBatch}`;
  //     const response = await axios.get(url);

  //     setPlan(response.data)
  //     console.log(response.data)
  //     if(response.data.length === 0 ) return;
  //     // setFormData((prev) => ({
  //     //   ...prev,
  //     //   "course_plan": response.data[0].course_plan,
  //     // }));
  //     // setSelectedCourse(response.data[0])
      
  
  //   } catch (e) {
  //     if (e.isAxiosError) {
  //       console.log(e.response.data.error)
  //     }
  //     else {
  //       console.log(e)
  //     }
  //   }

  // }

  // const getBatch = async (courseName) => {
  //   clearSelection("course_name")
  //   try {
  //     const url = `${env.BACKEND_URL}/course/batch/${formData.course_type}/${courseName}`;
  //     const response = await axios.get(url);
  //     console.log(url);
  //     setBatch(response.data)
  //     // setFormData((prev) => ({
  //     //   ...prev,
  //     //   "course_batch": response.data[0],
  //     // }));
  //     console.log(response.data)
  //     // getPlan(response.data[0])
      


  //   } catch (e) {

  //     if (e.isAxiosError) {
  //       console.log(e.response.data.error)
  //     }
  //     else {
  //       console.log(e)
  //     }
  //   }

  // }


  // const getCourse = async (courssType) => {
  //   clearSelection("course_type")
  //   try {
  //     const url = `${env.BACKEND_URL}/course/names/${courssType}`;
  //     const response = await axios.get(url);

  //     setCourse(response.data)
  //     // setFormData((prev) => ({
  //     //   ...prev,
  //     //   "course_name": response.data[0],
  //     // }));
  //     // getBatch(response.data[0])
  //     console.log(response.data)
  //   } catch (e) {
  //     if (e.isAxiosError) {
  //       console.log(e.response.data.error)
  //       // showTost(e.response.data.error, "red")
  //     }
  //     else {
  //       console.log(e)
  //       //   showTost("Unkown error", "red");
  //     }
  //   }

  // }



  // const setCurrentCourse = (value) => {
  //   setSelectedCourse(plan[value])
  // }
  useEffect(async () => {
    // await getCourse(formData.course_name);
  }, [])

  const formDataChange = (name, value) => {

    console.log(formData)
    console.log(name, value)
    setFormData(prev => ({
      ...prev,
      [name]: value
    }))
  }


  // const courseDataChange = (name, value) => {

  //   console.log(formData)
  //   console.log(name, value)
  //   setFormData(prev => ({
  //     ...prev,
  //     [name]: value
  //   }))

  //   if (name === "course_type") { getCourse(value) }
  //   else if (name === "course_name") { getBatch(value) }
  //   else if (name === "course_batch") { getPlan(value) }
  //   else setCurrentCourse(value)

  // }

  const showTost = (data) => {
    setToast(data)
    setTimeout(() => setToast(null), 3000)
  }


  console.log("hasura url", env.HASURA_URL)

  const validateEmail = (email) => {
        const re =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
  }

  const validatePhoneNo = (phone) => {
    const re =/^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/;
    return re.test(phone);
}

    

  const submitHandler = async () => {
    const {user_name: userName, user_email: userEmail, user_phone: userPhone, user_college : userCollege} = formData;
    
    // console.log("value",courseType, courseName, courseBatch, coursePlan, userName, userEmail, userPhone)
    if (!userName || !userEmail || !userPhone || !userCollege)
    {  showTost("All the fileds are mandatory"); return;}

    if(!validateEmail(userEmail)) {showTost("Please provide valid email"); return;}

    if(!validatePhoneNo(userPhone)) {showTost("Please provide valid phone number"); return;}

    const courseInfo = JSON.stringify({userName, userEmail, userPhone, userCollege})
    localStorage.setItem("courseInfo", courseInfo);

    navigate("/select-course")
    
  }
  return (<div>

    <div className="bg-white rounded-lg my-5 pb-5 mt-10 mb-5 md:w-1/2 shadow-md">

      {toast && <div id="toast-top-right" className="absolute flex items-center w-full max-w-xs p-4 space-x-4 font-xl font-semibold bg-sky-400 text-white divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 delay-200 ease-in-out bg-red-400" role="alert">
        <div className="text-md font-semibold">{toast}</div>
      </div>}

      <div className="flex flex-cols">
        <div className="w-3 h-16 bg-indigo-400 my-1 mx-4 rounded-lg">{' '}</div>
        <div className=''>
        <h2 className=" text-3xl tracking-wide  pt-2 pb-1">Mentorboxx courses </h2>
        <div className='text-slate-300 pb-3 '>Contact Information </div>
        </div>
        
      </div>
      

      <div className="flex flex-row">
        <div className="flex flex-col flex-grow">

          <div className="w-full px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
              Name
            </label>
            <input name="user_name" onChange={(input) => formDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Ratan Tata" />
            
          </div>

          <div className="w-full px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
              Email ID
            </label>
            <input name="user_email" onChange={(input) => formDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="email@domain.com" />
            
          </div>

          <div className="w-full px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
              Phone No
            </label>
            <input name="user_phone" onChange={(input) => formDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="9347XXXXXX" />
            
          </div>

          <div className="w-full px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
              College
            </label>
            <input name="user_college" onChange={(input) => formDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="IIT M" />
            
          </div>
          {/* <div className="w-full px-3 mb-6 md:mb-0 py-3 px-4 mb-3">
            {selectedCourse.course_fee && `Course Fee - Rs. ${selectedCourse.course_fee}`}
          </div> */}
          <div>

<button type='submit' className='mx-3 px-4 py-2 bg-violet-400 text-white rounded-md shadow-md my-5 text-right block' onClick={submitHandler} >
  {/* {loading && <span>LOadding...</span>} */}
  <div className="flex"> <div>Select course  </div>
  <div className='mt-1 ml-2'>
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
</svg>
    </div> </div>
</button>
</div>
        </div>
{/* 
        <div className="flex flex-col flex-grow">
          
          <div className="w-full px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
              Course Type
            </label>
            <div className="relative">
              <select name="course_type"  onChange={(input) => courseDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-state">
                <option selected disabled>Select any value</option>
                <option>Internship</option>
                <option>Job Guarantee</option>
                <option>Workshop</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>

          <div className="w-full px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
              Course
            </label>
            <div className="relative">
              <select name="course_name" disabled={course.length===0} onChange={(input) => courseDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed" id="grid-state">
                {(formData.course_name==="" || formData.course_name===undefined )?(<option selected disabled>Select any value</option>):<option disabled>Select any value</option>}
                {course && course.map(data => <option>{data}</option>)}

              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>

          <div className="w-full px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
              Batch
            </label>
            <div className="relative">
              <select name="course_batch" disabled={batch.length===0} onChange={(input) => courseDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed" id="grid-state">
              {(formData.course_batch==="" || formData.course_batch===undefined )?(<option selected disabled>Select any value</option>):<option disabled>Select any value</option>}
                {batch && batch.map(data => <option>{data}</option>)}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>
          </div>
          <div className="w-full px-3 mb-6 md:mb-0">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
              Plan
            </label>
            <div className="relative">
              <select name="course_plan" disabled={plan.length===0} onChange={(input) => courseDataChange(input.target.name, input.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed" id="grid-state">
              {(formData.course_plan==="" || formData.course_plan===undefined )?(<option selected disabled>Select any value</option>):<option disabled>Select any value</option>}
                {plan && plan.map((data, index) => <option value={index}>{data.course_plan ? data.course_plan : "NA"}</option>)}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
              </div>
            </div>

            
          </div>


        </div> */}

        

      </div>


    </div>
  </div>);
}

export default UserPayments;