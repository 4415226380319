import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import env from "../../utils/config"
// import MiniCard from '../../components/dashboard/miniCard';
import { AuthContext } from '../../context/authContext';

const InternDashboard = () => {

    const [isLeadsLoading,setLeadsLoading] = useState("false");
    const [leads,setLeads] = useState([]);
    const [payment, setPayment] =  useState({sum:0, count:0, list:[]})
    const [clicks, setClicks] = useState({form:0, payment:0});

    const auth = useContext(AuthContext);
    console.log("From dashboard",auth);

    const getPayments = async()=>{
        const url = `${env.BACKEND_URL}/dashboard/payment/${auth.authUser.hasuraUserData.id}`
        console.log(url)
        const response = await axios.get(url);

        if(response.data){
            setPayment({
                count: response.data.payments_aggregate.aggregate.count,
                sum: response.data.payments_aggregate.aggregate.sum.amount===null?0:response.data.payments_aggregate.aggregate.sum.amount,
                list:response.data.payments
            })
        }


    }

    const getClicks = async()=>{

        try{
            const payUrl = `${env.BACKEND_URL}/dashboard/click/${auth.authUser.hasuraUserData.shot_payment_id}`
            const formUrl = `${env.BACKEND_URL}/dashboard/click/${auth.authUser.hasuraUserData.short_form_id}`
            axios.get(payUrl).then(({data})=>{
                console.log("payclick", data)
                setClicks(prev=>({...prev, payment:data.clicks}))
            })

            axios.get(formUrl).then(({data})=>{
                setClicks(prev=>({...prev, form:data.clicks}))
            })
    
        }catch(e){
            console.log(e);
        }
 
    

    }
    useEffect(async () => {
        // setToast("visible")
        setLeadsLoading(true)
        const url = `${env.BACKEND_URL}/typeform/${auth.authUser.hasuraUserData.email}`
        console.log(url)
        const response = await axios.get(url);
        console.log(response.data)
        getClicks()
        getPayments()
        setLeads(response.data.typeform);
        setLeadsLoading(false)
    }, [auth])

    return (
        <div className='mx-5'>
            <span className="font-semibold text-stone-400 text-lg tracking-widest mx-4">Hi {auth.authUser&&  auth.authUser.user && auth.authUser.user.displayName } , </span>
            <h2 className="font-bold text-4xl tracking-wider mx-4">Welcome back <span role="img" aria-label="Waving Hand">👋</span> </h2>
        
            <div className="flex pt-5">
                <div className='mx-4'>
            <div className="flex flex-col p-4 bg-pink-100 rounded-lg shadow-md">
                <div className="flex">
                    <div className='p-3 mr-2 mb-2 text-yellow-500 bg-white rounded-full shadow-md'>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" /></svg>
                    </div>
                    <div className="flex flex-col">
                        <div className='font-bold'>Form Opens</div>
                        <div className='text-stone-400'>Marketing</div>
                    </div>
                </div>
                <div className='font-bold text-3xl'>{clicks.form}</div>
                <div className='font-bold text-md text-stone-400'>Clicks</div>
                
            </div>
        </div>
                {/* //Finace card */}
                <div className='mx-4'>
            <div className="flex flex-col p-4 bg-sky-100 rounded-lg shadow-md">
                <div className="flex">
                    <div className='p-3 mr-2 mb-2 text-yellow-500 bg-white rounded-full shadow-md'>
                    <svg xmlns="http://www.w3.org/2000/svg"className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" /></svg>
                    </div>
                    <div className="flex flex-col">
                        <div className='font-bold'>Payments </div>
                        <div className='text-stone-400'>Finance</div>
                    </div>
                </div>
                <div className='font-bold text-3xl'>{payment && payment.count}</div>
                <div className='font-bold text-md text-stone-400'>Users Paid</div>
            </div>
        </div>
        {/* Finace sum */}
        <div className='mx-4'>
            <div className="flex flex-col p-4 bg-indigo-100 rounded-lg shadow-md">
                <div className="flex">
                    <div className='p-3 mr-2 mb-2 text-yellow-500 bg-white rounded-full shadow-md'>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" /></svg>
                    </div>
                    <div className="flex flex-col">
                        <div className='font-bold'>Revenue</div>
                        <div className='text-stone-400'>Generated</div>
                    </div>
                </div>
                <div className='font-bold text-3xl'>{payment && payment.sum}</div>
                <div className='font-bold text-md text-stone-400'>Recived</div>
            </div>
        </div>
        {/*  */}
                

                <div className='mx-4'>
            <div className="flex flex-col p-4 bg-green-100 rounded-lg shadow-md">
                <div className="flex">
                    <div className='p-3 mr-2 mb-2 text-yellow-500 bg-white rounded-full shadow-md'>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" /></svg>
                    </div>
                    <div className="flex flex-col">
                        <div className='font-bold'>Payment Opens</div>
                        <div className='text-stone-400'>Finance</div>
                    </div>
                </div>
                <div className='font-bold text-3xl'>{clicks.payment}</div>
                <div className='font-bold text-md text-stone-400'>Clicks</div>
                
            </div>
        </div>
                
                <div className="basis-2/12">{'  '}</div>
                {/* <div className='basis-3/12'>
                    <div className='border-2 px-10 py-3 mb-8 mt-1 border-gray-300 shadow-md flex w-full '><span className='basis-5/6'>Referal Link - htttp://google.com </span> <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 basis-1/6 text-right" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
</svg> </div>
                    <div className='border-2 px-10 py-3 mt-1 border-gray-300 shadow-md flex w-full '><span className='basis-5/6'>Payment link - htttp://google.com</span><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 basis-1/6 text-right" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
</svg></div>
                </div> */}
            </div>

            <div className="flex my-3">
                <div className="basis-2/3 bg-white shadow-md mx-3 px-4 py-3 ">
                {/* Level progress */}

                <div>
                    <div className="flex">
                        <div className="basis-4/6" >
                            <h2 className="font-bold text-2xl">Community Influencer - Level 1</h2>
                        </div>
                        <div className="basis-2/6 " > <span className="border-2 px-2 py-1 rounded-2xl my-2 bg-sky-100 border-sky-100"> 5 Days left </span></div>
                       
                
                    </div>

                     
                    <div className="flex  my-4 border-b-2 pb-6">
                            <div className="basis-4/6  mr-6">
                                <div className="flex shadow-md rounded-full">
                                    <div className='bg-gray-200 h-4 rounded-l-full' style={{height:'1rem', width: "20%"}} >
                                    <div className='bg-sky-300 h-4 rounded-l-full ' style={{height:'1rem', width: "100%"}} />
                                    </div>
                                    <div className='bg-gray-200 h-4 ' style={{height:'1rem', width: "20%"}} >
                                    <div className='bg-sky-300 h-4 rounded-r-full ' style={{height:'1rem', width: "50%"}} />
                                    </div>
                                    <div className='bg-gray-200 h-4 ' style={{height:'1rem', width: "20%"}} />
                                    <div className='bg-gray-200 h-4 ' style={{height:'1rem', width: "20%"}} />
                                    <div className='bg-gray-200 h-4 rounded-r-full' style={{height:'1rem', width: "20%"}} />
                                </div>
                            </div>
                            <div className="basis-2/6">70%</div>
                        </div>

                </div>
                <div className='flex flex-row'>
                    <div className="basis-1/2 mr-2 border-r-2 ">

                    <div className="flex ">
                        <div className='text-indigo-700 text-2xl basis-1/4' >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 my-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>  
                        </div>
                        <div className='basis-2/4 pt-2'> Trainning Module</div>
                        <div className='basis-1/4 py-1 bg-green-200 rounded-lg text-center my-2 mx-8'> Completed</div>
                    </div>

                     <div className="flex ">
                        <div className='text-indigo-700 text-2xl basis-1/4' >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 my-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>  
                        </div>
                        <div className='basis-2/4 pt-2'> Trainning Module</div>
                        <div className='basis-1/4 py-1 bg-green-200 rounded-lg text-center my-2 mx-8'> Completed</div>
                    </div>

                     <div className="flex ">
                        <div className='text-indigo-700 text-2xl basis-1/4' >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 my-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>  
                        </div>
                        <div className='basis-2/4 pt-2'> Trainning Module</div>
                        <div className='basis-1/4 py-1 bg-green-200 rounded-lg text-center my-2 mx-8'> Completed</div>
                    </div>

           
                    </div>
                    <div className="basis-1/2">

                    
                    <div className="flex ">
                        <div className='text-indigo-700 text-2xl basis-1/4' >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 my-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>  
                        </div>
                        <div className='basis-2/4 pt-2'> Trainning Module</div>
                        <div className='basis-1/4 py-1 bg-green-200 rounded-lg text-center my-2 mx-8'> Completed</div>
                    </div>

                     <div className="flex ">
                        <div className='text-indigo-700 text-2xl basis-1/4' >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 my-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>  
                        </div>
                        <div className='basis-2/4 pt-2'> Trainning Module</div>
                        <div className='basis-1/4 py-1 bg-green-200 rounded-lg text-center my-2 mx-8'> Completed</div>
                    </div>

                     <div className="flex ">
                        <div className='text-indigo-700 text-2xl basis-1/4' >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-9 w-9 my-1" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>  
                        </div>
                        <div className='basis-2/4 pt-2'> Trainning Module</div>
                        <div className='basis-1/4 py-1 bg-green-200 rounded-lg text-center my-2 mx-8'> Completed</div>
                    </div>

           
                    </div>
                </div>
                </div>
                <div className="basis-1/3 shadow-md rounded-t-full mr-4	">
                    <div className="rounded-lg rounded-2xl	">
                        <h3 className='bg-slate-200 font-bold px-5 py-2 rounded-t-xl'>Leaderboard </h3>
                        <div className='py-1 px-2 bg-white rounded-b-xl'>
                            <table className="min-w-full">
                                <thead className='text-slate-400 text-left'>
                                    <th className='pl-3 py-2'>Avatar</th>
                                    <th className=' py-2'>Name</th>
                                    <th className='pr-3 py-2'>Revenue</th>
                                </thead>
                                <tbody>
                                    
                                    <tr className='my-5 border-b-2 my-2'>
                                        <td className='basis-2/12 pl-4'> 
                                        <img src="https://i.pravatar.cc/150?=a042581f4e2902673dd" className='basis-5/12 max-h-50 rounded-full' style={{"max-height": "40px", "maxWidth":'40px'}} alt='avatar'/></td>
                                        <td><div style={{'alignItems': 'baseline'}}>Hari Prasath M</div>
                                        </td>
                                        <td className='basis-4/12'>Rs 5000</td>
                                    </tr>

                                    <tr className='my-5 border-b-2 my-2'>
                                        <td className='basis-2/12 pl-4'> 
                                        <img src="https://i.pravatar.cc/150?=a042581f4e2902673dd" className='basis-5/12 max-h-50 rounded-full' style={{"max-height": "40px", "maxWidth":'40px'}} alt='avatar'/></td>
                                        <td><div style={{'alignItems': 'baseline'}}>Hari Prasath M</div>
                                        </td>
                                        <td className='basis-4/12'>Rs 5000</td>
                                    </tr>

                                    <tr className='my-5 border-b-2 my-2'>
                                        <td className='basis-2/12 pl-4'> 
                                        <img src="https://i.pravatar.cc/150?=a042581f4e2902673dd" className='basis-5/12 max-h-50 rounded-full' style={{"max-height": "40px", "maxWidth":'40px'}} alt='avatar'/></td>
                                        <td><div style={{'alignItems': 'baseline'}}>Hari Prasath M</div>
                                        </td>
                                        <td className='basis-4/12'>Rs 5000</td>
                                    </tr>

                                    <tr className='my-5 border-b-2 my-2'>
                                        <td className='basis-2/12 pl-4'> 
                                        <img src="https://i.pravatar.cc/150?=a042581f4e2902673dd" className='basis-5/12 max-h-50 rounded-full' style={{"max-height": "40px", "maxWidth":'40px'}} alt='avatar'/></td>
                                        <td><div style={{'alignItems': 'baseline'}}>Hari Prasath M</div>
                                        </td>
                                        <td className='basis-4/12'>Rs 5000</td>
                                    </tr>

                                    <tr className='my-5 border-b-2 my-2'>
                                        <td className='basis-2/12 pl-4'> 
                                        <img src="https://i.pravatar.cc/150?=a042581f4e2902673dd" className='basis-5/12 max-h-50 rounded-full' style={{"max-height": "40px", "maxWidth":'40px'}} alt='avatar'/></td>
                                        <td><div style={{'alignItems': 'baseline'}}>Hari Prasath M</div>
                                        </td>
                                        <td className='basis-4/12'>Rs 5000</td>
                                    </tr>

                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex mb-5   ">
                {/* Latest leads */}
                <div className='basis-full  '>
                    <div className="bg-white p-5 mx-4 shadow-md ">
                        <h2 className='font-bold py-2'>Latest Leads</h2>

                        <div className="">
                            <div className="flex text-stone-400 py-3">
                            <div className="basis-2/3">Student</div>
                            <div className="basis-1/3 text-right">Course</div>
                            </div>

                            {isLeadsLoading && (<svg role="status" className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 text-center my-5" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>)}
                                    {!isLeadsLoading &&  leads.length===0?"No Data": leads.slice(0,5).map(data=> (
                                        
                                        <div keys={data.id} className='py-2'>
                                        <div className="flex">
                                            <div className="flex basis-2/3">
                                            <div><img src={`https://i.pravatar.cc/150?=${data.id}`} className='rounded-lg' style={{"max-height": "50px", "maxWidth":'50px'}} alt='avatar'/></div>
                                                <div className="flex-col px-4">
                                                <div>{data.first_name}</div>
                                                <div>{data.college}</div>
                                                </div>
                                            </div>
                                            <div className="flex-col basis-1/3 text-right">
                                                <div>{data.area_of_intrest}</div>
                                                <div className='text-green-400'>Applied</div>
                                            </div>
                                        </div>
                                    </div>
                                            ))}


                        </div>
                    </div>
                </div>
                <div className='basis-full '>
                {/* Latest Payment */}

                <div className='basis-1/2'>
                    <div className="bg-white p-5 mx-4 shadow-md ">
                      
                       <div className="flex">
                       <h2 className='font-bold basis-3/4 '>Latest Payments</h2>
                       <div className="basis-1/4 text-right" > <button className='bg-violet-500 text-white px-2 py-2 shadow-md rounded-xl hover:shadow-2xl ' type='submit'>Click Me</button> </div>
                       
                       </div>
                        

                        <div className="">
                            <div className="flex text-stone-400 py-3">
                            <div className="basis-2/3">Student</div>
                            <div className="basis-1/3 text-right">Course</div>
                            </div>
                            
                            {payment && payment.list.length===0?"No Data":  payment.list.map(data=>
                                <div className='py-2'>
                                <div className="flex">
                                    <div className="flex basis-2/3">
                                    <div><img src={`https://i.pravatar.cc/150?=${data.id}`} className='rounded-lg' style={{"max-height": "50px", "maxWidth":'50px'}} alt='avatar'/></div>
                                        <div className="flex-col px-4">
                                        <div>Invoice - {data.transaction_id}</div>
                                        <div>{data.name} - {data.phone}</div>
                                        </div>
                                    </div>
                                    <div className="flex-col basis-1/3 text-right">
                                        <div>Rs {data.amount}</div>
                                        <div className='text-green-400'>{new Date(data.create_at).toLocaleString()}</div>
                                    </div>
                                </div>
                            </div>
                            )}

                        </div>
                    </div>
                    </div>

                </div>
            </div>
        </div>
    )

}
 
export default InternDashboard;