import React, { useContext, useState } from 'react';
import { AuthContext } from '../../context/authContext';

const UpdateProfile = () => {
    console.log("h1")

    const auth =  useContext(AuthContext);

    const [name, setName]= useState();

    const onSubmit = (event)=>{

        event.preventDefault();
        if(auth){
            auth.updateProfileName(name);
        }
    }


    return ( 
        <>
        <h1>Update profile</h1>
        <form onSubmit={onSubmit}>
           <input onChange={event=> setName(event.target.value)} />
           <button type="submit">Update</button>
        </form>
        </>
     );
}
 
export default UpdateProfile;