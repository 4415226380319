import React, { createContext, useMemo, useState, useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut  as firebaseSignOut, updateProfile} from "firebase/auth";
import axios from 'axios';
import env from "../utils/config";

export const AuthContext = createContext('default');

const AuthContexComponenet = ({children}) => {

  const history = useNavigate();
    
  const [authUser, setAuthUser] = useState(); 
  const [authLoding, setAuthLOading] =  useState(false);
  
  useEffect(()=>{
    const authData = localStorage.getItem('auth');
    setAuthUser(JSON.parse(authData));
  },[])
  const firebaseConfig = {
    apiKey: "AIzaSyCBIzX1bzjRoaQNJIybLrbXNqMY4y7evQk",
    authDomain: "mentorboxx-intern.firebaseapp.com",
    projectId: "mentorboxx-intern",
    storageBucket: "mentorboxx-intern.appspot.com",
    messagingSenderId: "382492421412",
    appId: "1:382492421412:web:d01697558a16c344cb97b1",
    measurementId: "G-EQ2SBD3YKK"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);


  const auth = getAuth(app);

  console.log(auth);


  const signIn = (email, password, openToast)=>{

    setAuthLOading(true)
    signInWithEmailAndPassword(auth, email, password).then(async (user)=>{
      
      const url = `${env.BACKEND_URL}/auth/${user.user.uid}`;
      const response = await axios.get(url);
      const hasuraUserData = response.data;

      localStorage.setItem('auth', JSON.stringify({...user, hasuraUserData}))
      setAuthUser({...user, hasuraUserData})
        history('/dashboard', {replace:true});
      setAuthLOading(false)
    }).catch(err=>{
      console.log(err.message.replace("Firebase: Error (auth/", "").replace(").", ""))
      openToast(err.message.replace("Firebase: Error (auth/", "").replace(").", ""))

      setAuthLOading(false)
    })
  }

  const signOut = ()=>{
    firebaseSignOut(auth).then(()=>{
      setAuthUser(null)
      localStorage.removeItem('auth')
      history('/login', {replace:true});
      console.log(authLoding)
    }).catch(err=>{console.log(err)})
  }

  const updateProfileName = (name)=>{

    setAuthLOading(true)
    updateProfile(auth.currentUser, {
      displayName: name
    }).then((data) => {
      setAuthLOading(false)
      console.log(data)
    }).catch(() => {
      setAuthLOading(false)
    });

  }

//   useEffect(()=>{
//     signIn('hariprasath2603@gmail.com', 'Hari@1234')
//   }, [])
  
  const authMemo = useMemo(()=>({
    authUser, 
    authLoding,
    updateProfileName,
    signIn,
    signOut
  }),[authUser, authLoding])
  

    return ( 
        <AuthContext.Provider value={authMemo}>
          {children}  
        </AuthContext.Provider>
     );
}
 
export default AuthContexComponenet;