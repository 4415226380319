import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import axios from 'axios';
import env from "../../../utils/config";


const AddUsers = () => {

    const [tableRows, setTableRows] = useState([]);

    const [allValid, setAllValid] = useState(true);

    const [clicked, setClicked] = useState(false);

    console.log("env",)

    useEffect(() => {
        setTableRows([{ id: 0, name: "Name" }, { id: 1, name: "Email" }, { id: 2, name: "Phone" }, { id: 3, name: "Clg" }, { id: 4, name: "Valid data" }, { id: 5, name: "Account created" }, { id: 6, name: "Retry" }])
    }, [])

    const [values, setValues] = useState([]);

    const validateRow = (rowData) => {

        const valid = [];
        let isValid = true;

        const validemail = String(rowData[1])
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        if (validemail === null) { valid.push("Email not valid") }

        if (rowData[2].length !== 10) { valid.push("Phone number not valid") }

        if ((rowData[0]).length < 3) { valid.push("Name must have minimum three letters") }

        if (rowData[3].length < 4) { valid.push("Clg name must have minimum three letters") }

        if (valid.length !== 0) isValid = false;

        return { isValid, msg: valid.join(", ") }

    }

    const changeHandler = (event) => {

        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const rowsArray = [];
                const valuesArray = [];

                // Iterating data to get column name and their values
                results.data.map((d) => {
                    rowsArray.push(Object.keys(d));
                    let rowData = Object.values(d);
                    const validData = validateRow(rowData)
                    if(!validData.isValid) setAllValid(false)
                    const secondary = [validData.isValid, "No", true, validData.msg, false];
                    rowData = rowData.concat(secondary)
                    valuesArray.push(rowData);
                    return "hi";
                });

                setValues(valuesArray);
            },
        });
    };


    const createSingleUsers = async ({phoneNo, name, email, clg})=>{

        const url = `${env.BACKEND_URL}/auth`;
        const data = {user:{
        phoneNo, name, isManager:false, isAdmin:false , email, clg
        }}
        const response = await axios.post(url,data, {headers:{"content-type":"application/json"}});

        return response;
    }

    const createUsers = async ()=>{
        setClicked(true)
        values.map(async (data, index) =>{
            const args = {phoneNo : data[2], name: data[0], email:data[1], clg: data[3]};

            setValues(prev=>{
                const previousArray = [...prev];    
                previousArray[index][8] = true; 
                return previousArray;
            })
            
            try{
                const response  = await createSingleUsers(args);       
                console.log(response);

                if(response.data.uid)
                setValues(prev=>{
                    const previousArray =  [...prev];
                    previousArray[index][5] = true;
                    previousArray[index][8] = false; 

                    return previousArray;
                })
            }
            catch(e){
                // console.log(e.response.data);
                let errorMsg = "";
                if(e.isAxiosError)   errorMsg = e.response.data.error;
                else errorMsg = e.message

                setValues(prev=>{
                    const previousArray =  [...prev];
                    previousArray[index][5] = errorMsg;
                    previousArray[index][8] = false; 

                    return previousArray;
                })
            }
            
        })
        
    }

    const status  = (createdOrError, isLoading)=>{
        if(createdOrError === true){return ( <span className='bg-green-200 border-2 border-green-200 px-2 py-2 rounded-md'>created</span> )}
        if(isLoading)  { return( <svg role="status" className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>)}
       return ( <span className='bg-red-200 px-2 py-2 rounded-md border-2 border-red-200'> {createdOrError}</span>)
    }

    return (<>

        <div className='px-7 pt-5'>
            <h2 className="font-bold text-2xl my-4 tracking-widest">Add users </h2>
            {/* File Uploader */}
            {values.length === 0 && <input
                type="file"
                name="file"
                onChange={changeHandler}
                accept=".csv"
                style={{ display: "block", margin: "10px auto" }}
            />}


            {/* Table */}

            {values.length > 0 &&
                <div className="bg-white shadow-md scroll-smooth overflow-auto max-width-full px-5">
                    <table className='table-auto w-full'>
                        <thead>
                            <tr className='rounded-t-3xl border-b-2 border-slate-100 text-slate-500 text-left'>
                                {tableRows.map((rows) => (<th className='px-6 py-4' key={rows.id}>{rows.name}</th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {values.map((value) =>
                            (
                                <tr key={value}>
                                    <td className='px-6 py-4' >{value[0]}</td>
                                    <td className='px-6 py-4' >{value[1]}</td>
                                    <td className='px-6 py-4' >{value[2]}</td>
                                    <td className='px-6 py-4' >{value[3]}</td>
                                    <td className='px-6 py-4' >{value[4] === true ? <span className='bg-green-200 border-2 border-green-200 px-2 py-2 rounded-md'>Yes</span> : <span className='bg-red-200 px-2 py-2 rounded-md border-2 border-red-200'>{value[7]}</span>}</td>
                                    <td className='px-6 py-4' >{
                                    
                                    status(value[5], value[8])
                                   }
                                  </td>
                                    <td className='px-6 py-4' >
                                        {value[6] === false ? <button type="submit" className='px-2 py-2 bg-violet-400 text-white rounded-md shadow-md'><svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
                                        </svg></button> : <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clipRule="evenodd" />
                                        </svg>}
                                    </td>
                                </tr>
                            )
                            )}
                        </tbody>
                    </table>
                </div>}
            {values.length > 0 && allValid && !clicked && <button type='submit' onClick={createUsers} className='px-2 py-2 bg-violet-400 text-white rounded-md shadow-md my-3'>Start creating users</button>}
        </div>

    </>);
}

export default AddUsers;