import React from 'react';


const Training = () =>  (<>

<div>

    <div className="bg-white shadow-md mx-2 py-3 rounded-md md:p-10 mt-10">
    <h2 className="font-bold text-2xl mx-4 mb-5 tracking-widest">Training Modules </h2>

    <p className='text-center text-gray-300 py-5 font-bold text-xl'>No Data</p>
</div>
</div>

    </>);


export default Training;