import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/authContext';
import env from "../../utils/config"

const Payments = (props) => {


const auth = useContext(AuthContext);


    const [tableHeaders, setTableHeaders] = useState();
    const [tableBody, setTableBody] = useState();
    const [toast, setToast] = useState("invisible")
    const hideToast = ()=>{
        setToast('visible')
        setTimeout(()=> setToast("invisible"), 3000)
    }
    
    let paymentsLink="";

    console.log(props)

    useEffect(async()=>{
        if(auth && auth.authUser){
         const uid =  auth.authUser.hasuraUserData.id;

         const url = `${env.BACKEND_URL}/user/payment/${uid}`
        const response = await axios.get(url);
    console.log(response.data.payments," Payment ")
        setTableBody(response.data.payments);

        }
        
    }, [auth.authUser])

    useEffect(async () => {
        setTableHeaders([{ "name": "Customer", "id": 0 }, { "name": "Contact", "id": 2 }, { "name": "Amount", "id": 4 }, { "name": "Transaction ID", "id": 5 }, { "name": 'Time', "id": 6 }])
        
    }, [])

    if(auth && auth.authUser) paymentsLink = auth.authUser.hasuraUserData.short_payment_url;


    console.log("Hi")
    return (
        <div className='mx-5'>


            <div className="bg-white rounded-lg my-5 pb-5 mt-10 mb-5 shadow-md">

            <div id="toast-top-right" className={`absolute flex items-center w-full max-w-xs p-4 space-x-4 font-xl font-semibold bg-sky-400 text-white divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800 delay-200 ease-in-out ${toast}`} role="alert">
    <div className="text-md font-semibold">Link copied</div>
</div>
                <div className="flex">
                    <div className="w-6 h-12 bg-sky-300 my-1 mx-4 rounded-lg">{' '}</div>
                    <h2 className="font-semibold text-xl tracking-wide mt-2 pt-3 px-2 pb-3">Payment Link</h2>
                </div>
                <span className='font-semibold text-slate-300 py-5 ml-4'>Use this link for user payments</span>

                <div className='border-2 ml-4 px-5 py-3 mb-8 w-1/2 mt-4 border-sky-300 shadow-md flex rounded-md '  onKeyDown={()=>console.log("hi")} tabIndex={0} role="button" onClick={()=>{navigator.clipboard.writeText(`https://${paymentsLink}`); hideToast()}}><span className='basis-5/6'>Referal Link - htttp://{paymentsLink} </span> <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 basis-1/6 text-right ml-auto mr-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg> </div>

            </div>
            <h2 className="text-3xl tracking-wide mt-2 pt-3 px-2 pb-3">Payments</h2>
            <div className="bg-white shadow-md scroll-smooth overflow-auto max-width-full px-5">
                
                <div className="flex">
                    <div className="w-6 h-20 bg-violet-300 my-3 mx-2 rounded-lg">{' '}</div>
                    <div className="flex flex-col">
                    <h2 className="text-2xl tracking-wide mt-2 pt-3 px-2 pb-2">Customers</h2>
                    <span className='font-semibold text-slate-300 py-1 ml-2'>List of customers imade payments on your link</span>
                    </div>
                </div>

                <table className="table-auto w-full">
                    <thead>
                        <tr className='rounded-t-3xl border-b-2 border-slate-100 text-slate-500 text-left'>
                            {tableHeaders && tableHeaders.map(data => <th key={data.id} className=" px-6 py-4">{data.name}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {tableBody && tableBody.map(data => (
                            <tr key={data.id}>
                                <td className='px-6 py-4 '>
                                    
                                    <div className="flex flex-col">
                                        <div >{data.name}</div>
                                        <div className='text-slate-500'>{data.phone}</div>
                                    </div>
                                    </td>
                                <td className='px-6 py-4 '>
                                    <div className="flex flex-col">
                                        <div>{data.email}</div>
                                        <div className='text-slate-500'>{data.phone_no}</div>
                                    </div>
                                    
                                    
                                    </td>
                                <td className='px-6 py-4 '>{data.amount}</td>
                                <td className='px-6 py-4 '>{data.transaction_id}</td>
                                <td className='px-6 py-4 '>{new Date(data.paid_at).toLocaleString()}</td>
                            </tr>

                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    );
}

export default Payments;